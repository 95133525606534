import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "tags", "close"]

  connect() {
  }

  showTagsPanel() {
    this.tagsTarget.classList.remove('d-none')
    this.closeTarget.classList.remove('d-none')
  }

  hideTagsPanel(event) {
    this.tagsTarget.classList.add('d-none')
    this.closeTarget.classList.add('d-none')
  }

  searchByKeywordOnEnter(event) {
    if (event.keyCode == 13) {
      this.searchByKeyword()
    }
  }

  searchByKeyword() {
    const keyword = this.inputTarget.value
    let location = '/'

    if (keyword !== '') {
      location = `/category/?keyword=${keyword}`
    }

    window.location.href = location
  }
}